console.log(window.sizefox);
var sizefox_jquery;
var $iframe;
var isChecked;
var reassuranceflag = 0;

// PROD
var domain = "https://server.smartsize.io";
var frontend = "https://smartsize.io/script/smartsize/prod";

// DEV
// var domain = "https://dev-server.smartsize.io";
//var frontend = "https://dev-client.smartsize.io";
//var frontend = "https://smartsize.io/script/smartsize/dev";

function loadJS() {
  var jsElm = document.createElement("script");
  jsElm.type = "application/javascript";
  jsElm.src = "https://cdn.shopify.com/s/files/1/0848/1321/8088/files/jquery-3.6.0.min.js";
  document.head.appendChild(jsElm);
}

function loadotherscripts() {
  jsElm = document.createElement("script");
  jsElm.type = "application/javascript";
  jsElm.src = `https://cdn.shopify.com/s/files/1/0848/1321/8088/files/jquery-ui.min.js`;
  document.head.appendChild(jsElm);

  var jsElm = document.createElement("link");
  jsElm.rel = "stylesheet";
  jsElm.type = "text/css";
  jsElm.href = `https://cdn.shopify.com/s/files/1/0848/1321/8088/files/sizefox.css`;
  jsElm.media = "all";
  document.head.appendChild(jsElm);
}

function reassurance() {
  reassuranceflag++;
  setTimeout(() => {
    if (
      typeof sizefox_jquery.ui !== "undefined" &&
      sizefox_jquery("#dialogsize").hasClass("ui-dialog-content")
    ) {
      console.log("Jquery UI working");
    } else {
      console.log("jquery UI not present");
      loadJS();
      loadotherscripts();
      /* $("#opensize")
        .prev()
        .remove();
      $("#opensize").remove(); */
      setTimeout(function() {
        sizefox_jquery("#dialogsize").remove();
        sizefox_jquery('div[aria-describedby="dialogsize"]').remove();

        //loadchart();
        console.log(reassuranceflag);
        chartUI(window.chartdata);
        console.log("reassuranceflag");
      }, 500);
      return true;
    }
    if (reassuranceflag < 61) reassurance();
    reassuranceflag++;
  }, 1000);
}

function waitForElementToDisplay(time) {
  if (typeof window.jQuery != "undefined" && jQuery.fn.jquery > "3.0") {
    sizefox_jquery = jQuery.noConflict(true);
    window.jQuery = window.$ = sizefox_jquery;
    // jQuery is loaded
    loadotherscripts();
    loadchart();
  } else {
    // jQuery is not loaded
    setTimeout(function() {
      waitForElementToDisplay(time);
    }, time);
  }
}

if (document.getElementById("sizefox-enable")) {
  if (typeof window.sizefox_jquery != "undefined") {
    loadotherscripts();
    loadchart();
  } else {
    loadJS();
    waitForElementToDisplay(500);
  }
} else if (window.sizefox.test == true) {
  app_preview(window.sizefox.currentChart);
}

function loadchart() {
  console.log("loaded");

  sizefox_jquery.post(`${domain}/api/script`, window.sizefox, function(data) {
    console.log(data);
    if (data?.size != null || data?.care != null) {
      chartUI(data);
    }
  });
}

function updateFocusedElementColor(id, color) {
  if (window.embed == true) {
    const clickedSpan = $iframe.contents().find(`#${id} span`);
    clickedSpan.css("background", clickedSpan[0].style.background ? "" : color);
  } else {
    const clickedSpan = $(`#${id} span`);
    clickedSpan.css("background", clickedSpan[0].style.background ? "" : color);
  }
}

function toggleSelection(id, selectionColor) {
  if (window.embed == true) {
    const el = $iframe.contents().find(`#${id}`);
    el.toggleClass("sizefox-td-click");
    updateFocusedElementColor(id, selectionColor);
  } else {
    $(`#${id}`).toggleClass("sizefox-td-click");
    updateFocusedElementColor(id, selectionColor);
  }
}

function sizechartUI(chart_content, color_schema) {
  const {
    webg_user_chart_items,
    webg_user_chart_measures,
    webg_user_chart_sizes,
  } = chart_content;

  if (webg_user_chart_sizes.length < 1 && webg_user_chart_items.length < 1) {
    return;
  }
  let res = "";
  if (webg_user_chart_sizes.length > 3) {
    res = `<table class="sizechart" style="width:auto;min-width: 100%;" cellspacing="0">`;
  } else {
    res = `<table class="sizechart" cellspacing="0">`;
  }
  res += `<tr><td style="background-color: ${color_schema.header_background};"></td>`;
  webg_user_chart_sizes.forEach((item, index) => {
    res += `<td key="header${index}" style="background-color: ${color_schema.header_background}; color: ${color_schema.header_text}">${item.size}</td>`;
  });
  res += "</tr>";
  webg_user_chart_items.forEach(async (item, rIndex) => {
    res += `<tr key="row${rIndex}" style="background-color: ${
      rIndex % 2 ? color_schema.zebra_lines : "#ffff"
    }">`;
    res += `<td style="background-color: ${
      rIndex % 2 ? color_schema.zebra_lines : "#ffff"
    };">${
      window.sizefox?.test == true
        ? item.chart_item_name
        : item.webg_chart_item_name
    }
    </td>`;
    if (window.sizefox?.test == true) {
      await chart_content.webg_user_chart_measurements[rIndex].forEach(
        async (cell, cIndex) => {
          res += `<td key="cell-${rIndex}-${cIndex}" id="cell-${rIndex}-${cIndex}" onclick="toggleSelection('cell-${rIndex}-${cIndex}', '${color_schema.focus}')"><span>`;
          res += cell_val_convert(
            webg_user_chart_measures[0].webg_chart_unitsId,
            cell.measure
          );
          res += `</span></td>`;
        }
      );
    } else {
      await item.webg_user_chart_measurements.forEach(async (cell, cIndex) => {
        res += `<td key="cell-${rIndex}-${cIndex}" id="cell-${rIndex}-${cIndex}" onclick="parent.toggleSelection('cell-${rIndex}-${cIndex}', '${color_schema.focus}')"><span>`;
        res += cell_val_convert(
          webg_user_chart_measures[0].webg_chart_unitsId,
          cell.measure
        );
        res += `</span></td>`;
      });
    }
    res += "</tr>";
  });
  res += "</table>";
  return res;
}

function cell_val_convert(unit, measure) {
  if (!measure) {
    return measure;
  }

  var regExp = /[a-zA-Z]/g;
  if (!regExp.test(measure)) {
    measure = measure.split("-");
    //measure = measure.replace(/\s+/g, "").split("-");
    if (unit == 1) {
      measure[0] = isChecked
        ? (parseFloat(measure[0]) / 2.54).toFixed(1)
        : measure[0];
      if (typeof measure[1] !== "undefined") {
        measure[1] = isChecked
          ? (parseFloat(measure[1]) / 2.54).toFixed(1)
          : measure[1];
      }
    } else if (unit == 2) {
      measure[0] = isChecked
        ? measure[0]
        : (parseFloat(measure[0]) * 2.54).toFixed(1);
      if (typeof measure[1] !== "undefined") {
        measure[1] = isChecked
          ? measure[1]
          : (parseFloat(measure[1]) * 2.54).toFixed(1);
      }
    }
    measure = measure.join(" - ");
  }
  return measure;
}

function chartUI(chart_data) {
  console.log(chart_data);

  window.chartdata = chart_data;

  window.chart_text = chart_data.size.chart_text;
  window.color_schema = chart_data.color_schema;
  window.embed = true;
  var chart = "";
  let chart_content;
  //stop recreating everything if chart was previously created
  if (reassuranceflag == 0) {
    chart += `<div style="display: flex; align-items: center;">`;
  }
  let img = "";
  if (chart_data.size != null) {
    if (reassuranceflag == 0) {
      var style = "";
      if (chart_data.size.icon != "sizenone") {
        img = `<img src="${chart_data.size.icon_url}" class="charticon form__label sizefoxtable-image"/>`;
      } else {
        style = 'style="margin-right: 5%;"';
      }
      isChecked =
        chart_data.size.chart_text.webg_user_chart_measures[0]
          .webg_chart_unitsId == 2;
      chart += `<span ${style}>
              <div id="dialogsize_place" style="display:none;">`;
    }
    chart += `<div id="dialogsize"><iframe id="under_frame"></iframe>`;
    chart_content = `<p id="ui-id-1" class="ui-dialog-title sizefox-product-title">${window.sizefox.data.productname}</p><div class="sizechart_content">`;
    if (
      chart_data.size.chart_text.webg_user_chart_items.length > 0 ||
      chart_data.size.chart_text.webg_user_chart_sizes.length > 0
    )
      chart_content += `<div class="sizechart_switch">
                        <div class="switch_wrapper" style="display: ${
                          chart_data.size.allow_metric_conversion
                            ? "flex;"
                            : "none"
                        }">  
                        <span class="unit">cm</span>
                        <label class="switch">
                        <input id="unit_selector" type="checkbox" ${
                          chart_data.size.chart_text.webg_user_chart_measures[0]
                            .webg_chart_unitsId == 2
                            ? "checked"
                            : ""
                        }>
                                    <span class="slider"></span>
                                </label>
                                <span class="unit">in</span>
                            </div>
                        </div>
                        <div class="sizechart_wrapper">
                            ${sizechartUI(
                              chart_data.size.chart_text,
                              chart_data.color_schema
                            )}
                        </div>`;
    chart_content += `<div class="${
      chart_data.size.chart_text.template_chart_id == 104 ||
      chart_data.size.chart_text.template_chart_id == 112 ||
      chart_data.size.chart_text.template_chart_id == 113 ||
      chart_data.size.chart_text.template_chart_id == 114 ||
      chart_data.size.chart_text.template_chart_id == 115
        ? "sizechart_instruction"
        : ""
    }">${chart_data.size.chart_text.chart_text}</div>
                    </div>`;
    chart += `</div>`;

    if (reassuranceflag == 0) {
      chart += `</div>
            </span>`;
    }
  }
  /* if (chart_data.care != null) {
    img = "";
    if (chart_data.care.icon != "carenone") {
      img =
        '<img src="' +
        chart_data.care.icon_url +
        '" class="charticon form__label"/>';
    }
    chart +=
      '<span><div id="dialogcare" title="' +
      window.sizefox.data.productname +
      '"> ' +
      '<div class="carechart_content">' +
      chart_data.care.chart_text +
      "</div>" +
      '</div><span style="display:flex; align-items:center;" id="opencare" class="opencare">' +
      img +
      ' <span class="form__label">' +
      chart_data.care.text +
      "</span></span></span>";
  }
   */

  if (reassuranceflag == 0) {
    // Button horizontal alignment
    let horizontal = "";
    if (
      sizefox_jquery("#sizefox-container").attr("data-btn-horizontal") &&
      sizefox_jquery("#sizefox-container").attr("data-btn-horizontal") != "left"
    ) {
      horizontal = sizefox_jquery("#sizefox-container").attr(
        "data-btn-horizontal"
      );
    } else {
      horizontal = sizefox_jquery("#sizefox-enable").attr(
        "data-btn-horizontal"
      );
    }

    // Button vertical alignment
    let vertical = "";
    if (
      sizefox_jquery("#sizefox-container").attr("data-btn-vertical") &&
      sizefox_jquery("#sizefox-container").attr("data-btn-vertical") != "top"
    ) {
      vertical = sizefox_jquery("#sizefox-container").attr("data-btn-vertical");
    } else {
      vertical = sizefox_jquery("#sizefox-enable").attr("data-btn-vertical");
    }

    // Button vertical class depending upon vertical alignment
    let vertical_class = "";
    if (vertical == "top") {
      vertical_class = "sizefox-btn-top";
    } else if (vertical == "center") {
      vertical_class = "sizefox-btn-center";
    } else {
      vertical_class = "sizefox-btn-bottom";
    }

    chart += ` </div><span id="opensize" class="opensize ${vertical_class}" style="display:flex; align-items:center;justify-content: ${horizontal};">${img}<span class="form__label">${chart_data.size.text}</span></span>`;
  }
/* 
  //For store missord.myshopify.com:
  if (document.getElementsByClassName("groups-btn").length > 0) {
    //sizefox_jquery(chart).addClass("sizefox-container-left !important");
    sizefox_jquery(chart).insertBefore(".groups-btn");
  }else 
   */
  if (reassuranceflag > 0) {
    sizefox_jquery(chart).appendTo("#dialogsize_place");
  } else if (document.getElementById("sizefox-container")) {
    sizefox_jquery(chart).appendTo("#sizefox-container");
  }

  //class to support store leni-swim.myshopify.com
  else if (
    document.getElementsByClassName("ProductMeta__PriceList").length > 0) 
  {
    sizefox_jquery(chart).insertAfter(".ProductMeta__PriceList");
  }
  /* //class to support store alphactory.myshopify.com
  else if (
    document.getElementsByClassName("product-form__input").length > 0) 
  {
    sizefox_jquery(chart).appendTo(".product-form__input");
  } */

// class to support store roman-luxura.myshopify.com
  else if (
    document.getElementsByClassName("tt-swatches-container").length > 0) 
  {
    sizefox_jquery(chart).insertAfter(".tt-swatches-container");
  } 
  // class to support store dolev-harosh1.myshopify.com
  else if (
    document.getElementsByClassName("prod__option-label__quantity").length > 0) 
  {
    sizefox_jquery(chart).insertAfter(".prod__option-label__quantity");
  }
  else if (
    document.getElementsByClassName("product-form__buttons").length > 0) 
  {
    sizefox_jquery(chart).insertBefore(".product-form__buttons");
  } else if (document.getElementsByClassName("custom_tags").length > 0) {
    sizefox_jquery(chart).insertAfter(".custom_tags");
  } else if (
    document.getElementsByClassName("apb_product_detail_options_container")
      .length > 0
  ) {
    sizefox_jquery(chart).appendTo(
      ".apb_product_detail_options_container:first"
    );
  } else if (document.getElementsByClassName("product__urgency").length > 0) {
    sizefox_jquery(chart).appendTo(".product__urgency:first");
  } else if (document.getElementsByClassName("add-to-cart-btn").length > 0) {
    sizefox_jquery(chart).appendTo(".add-to-cart-btn:first");
  }
  // class to support tinatily.myshopify.com
  else if (document.getElementsByClassName("btn-action").length > 0) {
    $(chart).insertBefore(".btn-action");
  } 

  
    else {
    sizefox_jquery(chart).insertBefore('[action="/cart/add"]');
  }
  
  sizefox_jquery("#dialogsize").css("overflow", "hidden !important");
  //$("#dialogsize").css("position", "absolute");
  // Process iframe content start
  $iframe = sizefox_jquery("#under_frame");
  $iframe.ready(function() {
    $iframe
      .contents()
      .find("body")
      .append(chart_content);
    $iframe
      .contents()
      .find("head")
      .append(
        `<style>html,body{margin:0px;} body{padding:0px 20px;margin-bottom: 100px;}</style>`
      );
    $iframe
      .contents()
      .find("head")
      .append(
        sizefox_jquery("<link/>", {
          rel: "stylesheet",
          href: `https://cdn.shopify.com/s/files/1/0848/1321/8088/files/sizefox.css`,
          type: "text/css",
        })
      );
    $iframe
      .contents()
      .find("body")
      .addClass("ui-dialog-content");
    $iframe.contents().find("body").append(`<script>
        if (typeof(jQuery) == "undefined") {
            window.jQuery = function (selector) { return parent.sizefox_jquery(selector, document); };
            jQuery = parent.sizefox_jquery.extend(jQuery, parent.sizefox_jquery);
        }
        window.embed = true;
        jQuery("table tr td:first-child").addClass("sizefox-nohover");
        jQuery("table tr:first").addClass("sizefox-nohover");
        console.log(jQuery("#unit_selector"));
        jQuery("#unit_selector").change(function() {
          console.log("status_changed");
          parent.isChecked = jQuery("#unit_selector").is(":checked");
          jQuery(".sizechart_wrapper").html(parent.sizechartUI(parent.chart_text,parent.color_schema));
        });`);
  });
  //
  // Process iframe content end

  if (chart_data.size != null)
    afterSizeChartUI(chart_data.size.chart_text, chart_data.size.text);
  if (chart_data.care != null) afterCareChartUI();
}

var sizechart_enable = false;
function afterSizeChartUI(chart_text, btn_text) {
  try {
    sizefox_jquery("#dialogsize").dialog({
      autoOpen: false,
      classes: {
        "ui-dialog": "smartsize_pop-up",
      },
      modal: true,
      draggable: false,
      resizable: false,
      position: { my: "center", mx: "center", at: "top" },
      create: function() {
        sizefox_jquery(this)
          .siblings()
          .find(".ui-dialog-title").html(`
                <h4 class="ui-dialog-popup-title-head center">${btn_text.toUpperCase()}</h4>`);
      },
      open: function(event, ui) {
        sizefox_jquery(this)
          .parent()
          .css({ top: 100 });
        sizefox_jquery("body").css({ height: "100vh", overflow: "hidden" });
        sizefox_jquery("body").addClass("backdrop_sizefox");
      },
      close: function(event, ui) {
        sizefox_jquery("body").css({ height: "auto", overflow: "auto" });
        sizefox_jquery("body").removeClass("backdrop_sizefox");
      },
    });

    sizefox_jquery("#opensize").on("click", function() {
      sizefox_jquery("#dialogsize").dialog("open");
      sizefox_jquery("#dialogsize").scrollTop(0);
      sizefox_jquery("#under_frame").scrollTop(0);
      var dialogchart = document.querySelector("#dialogsize");
      setTimeout(function() {
        dialogchart.scrollTop = 0;
        console.log(dialogchart.scrollTop);
      }, 0);
    });
    sizechart_enable = true;
  } catch (err) {
    console.log("size chart undefined");
    console.log(err);
  }

  reassuranceflag = 0;
  /* if (reassuranceflag == 0)  */ reassurance();
  /* $("#unit_selector").change(function() {
    console.log("status_changed");
    isChecked = $("#unit_selector").is(":checked");
    //TODO: Should I provide color_schema here also ?
    $(".sizechart_wrapper").html(sizechartUI(chart_text));
  }); */
}

function afterCareChartUI() {
  try {
    sizefox_jquery("#dialogcare").dialog({
      autoOpen: false,
      classes: {
        "ui-dialog": "smartsize_pop-up",
      },
      modal: true,
      draggable: false,
      resizable: false,
      position: { my: "center", mx: "center", at: "top" },
      open: function(event, ui) {
        sizefox_jquery(this)
          .parent()
          .css({ top: 100 });
        sizefox_jquery("body").css({ height: "100vh", overflow: "hidden" });
      },
      close: function(event, ui) {
        sizefox_jquery("body").css({ height: "auto", overflow: "auto" });
      },
    });

    sizefox_jquery("#opencare").on("click", function() {
      sizefox_jquery("#dialogcare").dialog("open");
    });

    sizefox_jquery("#dialogcare").hover(function() {
      sizefox_jquery("td").click(function() {
        sizefox_jquery(this).toggleClass("sizefox-td-click");
      });
    });
    if (sizechart_enable == true) {
      sizefox_jquery("#opencare")
        .parent()
        .addClass("spanspace");
    }

    sizefox_jquery("#dialogcare table").addClass("sizefoxnoclasstable");
  } catch (err) {
    console.log("Care chart undefinded");
  }
}

function app_preview(chart_data) {
  var chart = "";
  sizefox_jquery = jQuery;
  window.color_schema = chart_data.color_schema;
  chart += `<div style="align-items: center;">`;
  if (window.sizefox.currentChart != null) {
    isChecked = chart_data.webg_user_chart_measures[0].webg_chart_unitsId == 2;
    chart += `<div id="dialogsize"><p id="ui-id-1" class="ui-dialog-title sizefox-product-title">Name of Product</p><div class="sizechart_content">`;
    if (
      chart_data.webg_user_chart_items.length > 0 ||
      chart_data.webg_user_chart_sizes.length > 0
    )
      //
      chart += `<div class="sizechart_switch">
                            <div class="switch_wrapper" style="display: ${
                              window.sizefox.currentChart
                                .allow_metric_conversion
                                ? "flex;"
                                : "none"
                            }">
                                <span class="unit">cm</span>
                                <label class="switch">
                                    <input id="unit_selector" type="checkbox" ${
                                      chart_data.webg_user_chart_measures[0]
                                        .webg_chart_unitsId == 2
                                        ? "checked"
                                        : ""
                                    }>
                                    <span class="slider"></span>
                                </label>
                                <span class="unit">in</span>
                            </div>
                        </div>
                        <div class="sizechart_wrapper">
                        ${sizechartUI(chart_data, chart_data.color_schema)}
                        </div>`;
    chart += `<div class="${
      chart_data.chartdata.chart_id == 104 ||
      chart_data.chartdata.chart_id == 112 ||
      chart_data.chartdata.chart_id == 113 ||
      chart_data.chartdata.chart_id == 114 ||
      chart_data.chartdata.chart_id == 115
        ? "sizechart_instruction"
        : ""
    }">${chart_data.chartdata.content}</div>
                    </div>
                </div>`;
  }
  sizefox_jquery(chart).appendTo("#sizefox-container");

  sizefox_jquery("table tr td:first-child").addClass("sizefox-nohover");
  sizefox_jquery("table tr:first").addClass("sizefox-nohover");

  sizefox_jquery("#unit_selector").change(function() {
    console.log("status_changed");
    isChecked = sizefox_jquery("#unit_selector").is(":checked");
    sizefox_jquery(".sizechart_wrapper").html(
      sizechartUI(chart_data, chart_data.color_schema)
    );
  });
}
